import { Controller } from "stimulus"

export default class extends Controller {
    connect() { 
        $('#international_scout_recommedation_form').on('submit',function(){
          var is_return = true;
  
          var date = $('#date').val()
          var category = $('#international_scout_recommend_category').val()
          // var scoutingScore = $('#last_seen_date').val()
          // var currentAvailability = $('#current_availability').val()
  
          if (date==null || date==''){
            alert('Insert Date')
            is_return = false
          } else if (category==null || category==''){
            alert('Insert Category')
            is_return = false
          }
          return is_return
        });
      }

    uploadTrigger(){
        var multiUploadInput = document.getElementById("upload-input");
        multiUploadInput.click(); // this will trigger the click event
    }
    displayChangedFile(event){
        var changedFileInput = document.getElementById("upload-input");
        var divChangedFiletext = document.getElementById("upload-input-text");
        var changedFile = changedFileInput.files;

        var fileBuffer = new DataTransfer();
        if(changedFile.length!=0){
            Object.keys(changedFile).forEach(function (key, index) {
                let file = changedFile[key];
                fileBuffer.items.add(file);
            })
            changedFileInput.files = fileBuffer.files;

            if (changedFileInput.files.length>0) {
                divChangedFiletext.value=changedFileInput.files[0].name
            }
            else{
                divChangedFiletext.value=''
            }
        }
    }
    
    removeChangedFile(event){
        var divChangedFiletext = document.getElementById("upload-input-text");

        divChangedFiletext.value=null
        document.getElementById("upload-input").value = null;
        
    }

    showRecommendation(event){
        const internationalScoutRecommendId = event.target.getAttribute("internationalScoutRecommendId")
  
        if ($(`#international-scout-recommend-${internationalScoutRecommendId}`).is(":visible")==true){
          $(`#international-scout-recommend-${internationalScoutRecommendId}`).fadeOut()
          $(`#international-scout-recommend-${internationalScoutRecommendId}`).hide() 
        }
        else{
          $(`#international-scout-recommend-${internationalScoutRecommendId}`).fadeIn()
          $(`#international-scout-recommend-${internationalScoutRecommendId}`).show()
        }

        let data = { 
            "user_id":this.player_id,
            "page" : 'index',
            "action" : 'show',
            "international_scout_recommned_id": internationalScoutRecommendId,            
        }
        setLogs(data)
    }

}