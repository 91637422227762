import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  add_video_upload(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_VIDEO/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
    $('.player-select').select2({
      width: '100%',
      templateResult: formatRepo,
      templateSelection: formatRepoSelection,
      ajax: {
        url: '/analytics/pitcher_videos/getPitchers',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          var query = {
              search: params.term
          }
          return query;
        },
        processResults: function (data) {
          return {
            results: data.players,
          };
        },
        cache: true
      },
    });
  }
  
  remove_video_upload(event) {
    const wrapper = event.target.closest(".pitcher-video")
    if (wrapper.dataset.newRecord == "true") {
        wrapper.remove()
    }
    else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = "none"
    }
  }

  getKBOGames(event){
    var date = $('#game_date').val()
    this.fetchData(date)
  }
  
  fetchData(date) {
    $.ajax({
      url: `/analytics/pitcher_videos/getKBOGames?game_date=${date}&game_year=${date.split('-')[0]}`,
      method: 'get',
      async: false,
      success: function (data){
        $('#pitcher_video_games').empty()
        for (let item of data['games']) {
          let option = `<option value=${item['game_id']}>(${item['game_type']}) ${item['away_team']} vs ${item['home_team']} - ${item['game_number']}차전</option>`;
          $('#pitcher_video_games').append(option)
        }
      },
    });
  }
 
  // Single Upload

  singleUploadTrigger(event){
    const wrapper = event.target.closest(".pitcher-video-contents")
    var singleUploadInput = wrapper.querySelector('#pitcherVideoInput')
    singleUploadInput.click(); // this will trigger the click event
  }

  displayChangedFile(event){
    const wrapper = event.target.closest(".pitcher-video-contents")
    var changedFileInput = wrapper.querySelector('#pitcherVideoInput')
    var divChangedFiletext = wrapper.querySelector('#pitcher-video-upload-input-text')
    var changedFile = changedFileInput.files;

    var fileBuffer = new DataTransfer();
    if(changedFile.length!=0){
      Object.keys(changedFile).forEach(function (key, index) {
        let file = changedFile[key];
        fileBuffer.items.add(file);
      })
      changedFileInput.files = fileBuffer.files;

      if (changedFileInput.files.length>0) {
        divChangedFiletext.value=changedFileInput.files[0].name
      }
      else{
        divChangedFiletext.value=''
      }
    }
  }

  removeChangedFile(event){
    const wrapper = event.target.closest(".pitcher-video-contents")
    var changedFileInput = wrapper.querySelector('.pitcherVideoInput')
    var divChangedFiletext = wrapper.querySelector('.pitcher-video-upload-input-text')

    divChangedFiletext.value=null
    changedFileInput.value = null;
  }


  // Multiple Upload
  multipleUploadTrigger(){
    var multiUploadInput = document.getElementById("multi-upload-input");
    multiUploadInput.click(); // this will trigger the click event
  }

  multipleUpload(event){
    var multipleUploadInput = document.getElementById("multi-upload-input");
    var fileList = multipleUploadInput.files;

    $(".pitcher-video").remove();
    const linkTarget = $("#pitcher-video-links")[0];
    var injury_count = 10

    Object.keys(fileList).forEach(function (key, index) {
      let file = fileList[key];
      //const now = new Date().getTime();
      const idTag = `pitcher_video_each_pitcher_videos_attributes_${String(injury_count)}`;
      const content = $("#pitcher-video-template-target")[0].innerHTML.replace(
        /NEW_VIDEO/g,
        String(injury_count)
      );
      linkTarget.insertAdjacentHTML("beforebegin", content);
  
      $(`#${idTag}_player_id`).select2({
        width: '100%',
        ajax: {
          url: 'analytics/pitcher_videos/getPitchers',
          dataType: 'json',
          delay: 250,
          data: function (params) {
              var query = {
                  search: params.term
              }
              return query;
          },
          processResults: function (data) {
              return {
              results: data.players,
              };
          },
          cache: true
        },
      });
      $(`#${idTag}_video_name`).val(file.name);
      $(`#${idTag}_video_path`).val(file);
      injury_count = injury_count + 1
    });
  }

}

function formatRepo (repo) {
  if (repo.loading) {
    return repo.text;
  }

  var $container = $(

    "<div class='flex items-center gap-1 p-1'>"+
      "<img class='w-16 h-16' src='" + repo.thumnail + "'>"+
      "<div class='flex flex-col'>"+
          '<strong class="text-slate-900 text-sm font-medium dark:text-slate-200 select2-result-repository__title"></strong>'+
          '<span class="text-slate-500 text-sm font-medium dark:text-slate-400 select2-result-repository__description"></span>'+
      "</div>"+
    "</div>"
  );

  $container.find(".select2-result-repository__title").text(repo.title);
  $container.find(".select2-result-repository__description").text(repo.text);

  return $container;
}

function formatRepoSelection (repo) {
  return repo.full_name || repo.title;
}